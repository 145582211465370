
import serve1 from "./assets/1.png"
import serve2 from "./assets/2.png";
import serve3 from "./assets/3.png";
import serve4 from "./assets/4.png";
import serve5 from "./assets/5.png";
import serve6 from "./assets/6.png";

export const services = [
	{
		id: 1,
		src: serve1,
		title: "اذربيجان",

		desc: "العرض الاول: اذربيجان 5 ايام 4 ليالى	العرض يشمل:	الاقامة بالفندق	وجبات الإفطار	جولات يومية وتوصيل	السعر للشخصين 2350 ريال",
	

	},

	{
		id: 2,
		src: serve2,
		title: "جورجيا",

		desc: "العرض الثانى:چورچيا6 ايام 5 ليالى	العرض يشمل:	الاقامة بالفندق	وجبات الإفطار	جولات وتوصيل	السعر للشخصين 3550ريال",
	
	},
	{
		id: 3,
		src: serve3,
		title: "اسطنبول",

		desc: "العرض الثالث : اسطنبول	5 ايام 4 ليالى	العرض يشمل:	الاقامة بالفندق	وجبات الإفطار	جولات وتوصيل	السعر للشخصين3450ريال",
	
	
	},
	{
		id: 4,
		src: serve4,
		title: "(فيلا شاطئية) المالديف",

		desc: "العرض الرابع: المالديڤ (فيلا شاطئية)	4 ايام 3 ليالى	العرض يشمل:	الإقامة بفندق  HOLIDAY INN KANDOOMA	وجبات الإفطار و العشاء	السعر للشخص 2250 ريال",
	
	
	},
	{
		id: 5,
		src: serve5,
		title: "(فيلا مائية) المالديف",

		desc: " العرض الخامس : المالديڤ (فيلا مائية)	4 ايام 3 ليالى	العرض يشمل:	الإقامة بفندق  HOLIDAY INN KANDOOMA	وجبات الإفطار و العشاء	السعر للشخص 2850ريال",
	
	},{
		id: 6,
		src: serve6,
		title: "المالديف",

		desc: "العرض السادس:  المالديڤ	5 ايام 4 ليالى	العرض يشمل:	الإقامة بفندق  HOLIDAY INN KANDOOMA	ليلتين فيلا شاطئية + ليلتين فيلا مائية	وجبات الإفطار و العشاء	السعر للشخص 2990 ريال",
	},
];