import React from 'react'

const CallToAction = () => {
  return (
<div class={`social-links-fixed`}>
			<ul class=" d-flex justify-content-center align-items-center gap-4">
				<div className="container-calls ">
					<li class="whatsapp">
						<a target="_blank" href="https://wa.me/+966163249989">
							<span class="fab fa-whatsapp"></span>
						</a>
					</li>
				</div>

				<div className="container-calls">
					<li class="twitter">
						<a href="tel:+966163249989">
							<span class="fas fa-phone"></span>
						</a>
					</li>
				</div>
			</ul>
		</div>  )
}

export default CallToAction