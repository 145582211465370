import React from "react";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./App.css";

import web6 from "./assets/6-web.png";
import web5 from "./assets/5-web.png";
import web4 from "./assets/4-web.png";
import web3 from "./assets/3-web.png";
import web2 from "./assets/2-web.png";
import web1 from "./assets/1-web.png";

import serve1 from "./assets/1.png";
import serve2 from "./assets/2.png";
import serve3 from "./assets/3.png";
import serve4 from "./assets/4.png";
import serve5 from "./assets/5.png";
import serve6 from "./assets/6.png";

import CallToAction from "./components/CallToAction";
import Services from "./components/Services";

function App() {
	const slider = [
		{
			id: 6,
			srcWeb: web1,
			srcMobile: serve1,
		},
		{
			id: 5,
			srcWeb: web2,
			srcMobile: serve2,

		},
		{
			id: 4,
			srcWeb: web3,
			srcMobile: serve3,

		},
		{
			id: 3,
			srcWeb: web4,
			srcMobile: serve4,

		},
		{
			id: 2,
			srcWeb: web5,
			srcMobile: serve5,

		},
		{
			id: 1,
			srcWeb: web6,
			srcMobile: serve6,

		},
	];
	return (
		<div>
			<div className="pb-5">
				<Swiper
					modules={[Navigation, Autoplay]}
					loop={true}
					spaceBetween={10}
					slidesPerView={1}
					autoplay={{ delay: 2000, disableOnInteraction: false }}
					pagination={{ clickable: true }}
					navigation={{
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					}}
				>
					<>
						<div className="swiper-button-prev "></div>
						<div className="swiper-button-next "></div>
					</>
					{slider.map((slider, index) => {
						return (
							<SwiperSlide key={index}>
								<div className="">
									<img
										height={"100%"}
										srcSet={`  ${slider.srcMobile} 320w 600h ,${slider.srcWeb} 1400w`}
										// sizes="(max-width: 600px) 320px, 1400px"
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>

			<Services />

			<CallToAction />
			<div className="sliderConatiner"></div>
		</div>
	);
}

export default App;
