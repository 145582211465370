import React from "react";
import { services } from "../data";

const Services = () => {
	return (
		<div  className=" text-center py-5  px-3 ">
			<h1 className="mb-4">الرحلات</h1>
			<p>اجمل وامتع الرحلات لاسيا وأوروبا</p>

			<div className="row  justify-content-center gap-sm-0 gap-md-3 gap-lg-5  ">
				{services.map((item) => (
					<div className="col-sm-10 col-md-5 col-lg-3 bg-white mb-3 rounded">
						<div className="">
							<img src={item.src} className="rounded mt-3 " loading="lazy" />
						</div>

						<div className="py-1 text-center ">
							<h5 className="fw-bold">{item.title}</h5>
							<p className="text-serves ">{item.desc}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Services;
